import { useContext, useState, useEffect } from "react";
import { AuthContext } from "./context/AuthContext";
import { Routes, Route, BrowserRouter, useLocation } from "react-router-dom";
import Login from "./pages/auth/Login";
import Dashboard from "./pages/admin/Dashboard";
import Inventory from "./pages/admin/Inventory/Inventory";
import Boneyard from "./pages/admin/Boneyard/Boneyard";
import Committed from "./pages/admin/Committed/Committed";
import Tools from "./pages/admin/Tools/Tools";
import Return from "./pages/admin/Return/Return";
import InventoryItemDetails from "./pages/admin/Inventory/InventoryItemDetails";
import CommittedDetails from "./pages/admin/Committed/CommittedDetails";
import BoneyardItemDetails from "./pages/admin/Boneyard/BoneyardItemDetails";
import ToolRequests from "./pages/admin/Tools/ToolRequests";
import ToolsInUsersPossession from "./pages/admin/Tools/ToolsInUsersPossession";
import { PrintAllInventoryItems } from "./pages/admin/Inventory/PrintAll";
import ReturnDetails from "./pages/admin/Return/ReturnDetails";

import ToolDetail from "./pages/admin/Tools/ToolDetail";
import Jobs from "./pages/admin/Jobs/Jobs";
import HomePage from "./pages/admin/HomePage/HomePage";
import DeliverFromStaging from "./pages/admin/Jobs/JobDetails/Tabs/DeliverFromStaging";
import CreateStockout from "./pages/admin/Pulls/CreateStockout";
import PullDetails from "./pages/admin/Pulls/PullDetails";
import PullRequests from "./pages/admin/Pulls/PullRequests";
import CreatePullRequest from "./pages/admin/Pulls/CreatePullRequest";
import DailyStockouts from "./pages/admin/Pulls/DailyStockouts";
import InventoryTransactions from "./pages/admin/Inventory/InventoryTransactions";
import BoneyardTransactions from "./pages/admin/Boneyard/BoneyardTransactions";
import CommittedTransactions from "./pages/admin/Committed/CommittedTransactions";
import StagedTransaction from "./pages/admin/Staged/StagedTransaction";

// ASSET MANAGEMENT
import EmployeeListComponent from "./pages/admin/AssetManagement/EmployeeAssignment/EmployeesListComponent";
import AssetListComponent from "./pages/admin/AssetManagement/Assets/AssetList/AssetListComponent";
import AssetDetailComponent from "./pages/admin/AssetManagement/Assets/AssetDetail/AssetDetailComponent";
import RequestsComponent from "./pages/admin/AssetManagement/Assets/AssetRequests/RequestsComponent";
import AssignedAssetsComponent from "./pages/admin/AssetManagement/Assets/AssignedAssets/AssignedAssetsComponent";
import DamageReportComponent from "./pages/admin/AssetManagement/Assets/DamageReport/DamageReportComponent";
import DamageComponent from "./pages/admin/AssetManagement/Assets/AssetDetail/DamageReports/DamageComponent";

import Employees from "./pages/admin/AssetManagement/Assets/Employees/Employees";
import PrintAssetList from "./pages/admin/AssetManagement/Assets/AssetList/PrintAssetList";
import PrintBehmerStickers from "./pages/admin/AssetManagement/Assets/AssetList/PrintBehmerStickers";
import PrintPullDetails from "./pages/admin/Pulls/PrintPullDetails";
import Vehicles from "./pages/admin/Staged/Vehicles";
import JobDetails from "./pages/admin/Jobs/JobDetails/JobDetails";
import VehicleDetails from "./pages/admin/Staged/VehicleDetails";
import InventoryCounts from "./pages/admin/Inventory/InventoryCount/InventoryCounts";
import InventoryCount from "./pages/admin/Inventory/InventoryCount/InventoryCount";
import NeedToPost from "./pages/admin/NeedToPost/NeedToPost";
import StagedAndTrucks from "./pages/admin/Staged/StagedAndTrucks";
import PosPage from "./pages/admin/POs/PosPage";
import CreateNewReturn from "./pages/admin/Return/CreateNewReturn";
import SentryTest from "./pages/admin/Sentry/SentryTest";

const App = () => {
  const { authToken } = useContext(AuthContext);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={authToken === null ? <Login /> : <Dashboard />}
        >
          <Route path="/" element={<HomePage />} />
          <Route path="/sentry" element={<SentryTest />} />
          <Route path="/need-to-post" element={<NeedToPost />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/pending-pos" element={<PosPage />} />
          <Route path="/pending-pos/:poId" element={<PosPage />} />
          <Route path="/jobs" element={<Jobs />} />
          <Route
            path="/jobs/:jobId/staged/:transactionId"
            element={<StagedTransaction />}
          />
          <Route path="/jobs/:id" element={<JobDetails />} />
          <Route path="/jobs/:id/:section" element={<JobDetails />} />

          {/* :section MATCHING: pos, pull-requests, staged, delivered, committed */}
          <Route path="/jobs/:id/deliver" element={<DeliverFromStaging />} />
          <Route path="/inventory" element={<Inventory />} />
          <Route
            path="/inventory/transactions"
            element={<InventoryTransactions />}
          />
          <Route path="/inventory/:id" element={<InventoryItemDetails />} />
          <Route path="/inventory/print" element={<PrintAllInventoryItems />} />
          <Route path="/inventory/counts" element={<InventoryCounts />} />
          <Route path="/inventory/counts/new" element={<InventoryCount />} />
          <Route path="/inventory/counts/:id" element={<InventoryCount />} />
          <Route path="/boneyard" element={<Boneyard />} />
          <Route
            path="/boneyard/transactions"
            element={<BoneyardTransactions />}
          />
          <Route path="/boneyard/:id" element={<BoneyardItemDetails />} />
          <Route path="/committed" element={<Committed />} />
          <Route
            path="/committed/transactions"
            element={<CommittedTransactions />}
          />
          <Route path="/committed/:id" element={<CommittedDetails />} />
          <Route path="/tools" element={<Tools />} />
          <Route path="/tools/:id" element={<ToolDetail />} />
          <Route path="/tools/requests" element={<ToolRequests />} />
          <Route path="/staged" element={<StagedAndTrucks />} />
          <Route path="/staged/:section" element={<StagedAndTrucks />} />
          <Route
            path="/tools/possession"
            element={<ToolsInUsersPossession />}
          />
          <Route path="/return" element={<Return />} />
          <Route path="/return/new" element={<CreateNewReturn />} />
          <Route path="/return/:id" element={<ReturnDetails />} />
          <Route path="/pull-requests" element={<PullRequests />} />
          <Route path="/pull-requests/:id" element={<PullDetails />} />
          <Route path="/pull-requests/new" element={<CreatePullRequest />} />
          <Route
            path="/jobs/:jobId/pull-requests/new"
            element={<CreatePullRequest />}
          />
          <Route path="/daily-stockouts/new" element={<CreateStockout />} />
          <Route
            path="/jobs/:jobId/daily-stockouts/new"
            element={<CreateStockout />}
          />
          <Route path="/daily-stockouts" element={<DailyStockouts />} />
          <Route path="/daily-stockouts/:id" element={<PullDetails />} />
          <Route
            path="/pull-requests/:id/print"
            element={<PrintPullDetails />}
          />
          <Route
            path="/daily-stockouts/:id/print"
            element={<PrintPullDetails />}
          />

          {/*vvvvv NEW ASSET MANAGEMENT ROUTES vvvvv*/}
          <Route path="/assets/:type" element={<AssetListComponent />} />
          <Route path="/assets/:type/:id" element={<AssetDetailComponent />} />
          <Route path="/assets/requests" element={<RequestsComponent />} />
          <Route
            path="/assets/assigned"
            element={<AssignedAssetsComponent />}
          />
          <Route
            path="/assets/:type/:id/assign/"
            element={<EmployeeListComponent />}
          />
          <Route
            path="/assets/:type/:id/file-damage-report"
            element={<DamageReportComponent />}
          />
          <Route
            path="/assets/:type/:id/damage-reports"
            element={<DamageComponent />}
          />
          <Route path="/employees" element={<Employees />} />
          <Route
            path="/assets/:type/:branch/qr-codes"
            element={<PrintAssetList />}
          />
          <Route path="/behmer-stickers" element={<PrintBehmerStickers />} />
          {/*^^^^^ NEW ASSET MANAGEMENT ROUTES ^^^^^*/}
          <Route path="/vehicles" element={<Vehicles />} />
          <Route path="/vehicles/:id" element={<VehicleDetails />} />
        </Route>
        <Route path="*" element={<div>404 error</div>} />
        <Route path="/error401" element={<div>401 error</div>} />
        <Route path="/error403" element={<div>403 error</div>} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
