import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  FilledInput,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  Menu,
  MenuItem,
  Modal,
  Select,
  TextareaAutosize,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  Search,
  AddAPhoto,
  FileUpload,
  Close,
  Print,
  MoreVert,
  Delete,
} from "@mui/icons-material";
import InventoryTracking from "../../../api/InventoryTracking";
import { SnackAlertContext } from "../../../context/SnackAlertContext";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import PulledItemCard from "../../../component/PulledItemCard";
import CategorySearch from "../../../component/CategorySearch";
import escapeCharacters from "../../../helpers/escapeCharacters";
import PageContent from "../../../component/PageContent";
import { GrabsContext } from "../../../context/GrabsContext";
import toFixedIfNecessary from "../../../helpers/toFixedIfNecessary";
import SelectTruckModal from "../Jobs/JobDetails/Tabs/SelectTruckModal";
import { useTheme } from "@emotion/react";
import EditPulledItems from "./EditPulledItems";
import { LoadingButton } from "@mui/lab";
import imageCompression from "browser-image-compression";
import { DarkLightModeContext } from "../../../context/DarkLightModeContext";
import momentPhoenix from "../../../helpers/momentPhoenix";
import * as Sentry from "@sentry/react";

const PullDetails = () => {
  const { isDarkMode } = useContext(DarkLightModeContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const { authToken, userBranch, role, isMaterialAppAdmin } =
    useContext(AuthContext);
  const { openSnackMessage } = useContext(SnackAlertContext);
  const [details, setDetails] = useState(null);
  const [confirmPullModal, setConfirmPullModal] = useState(false);

  // Image Upload
  const uploadRef = useRef(null);
  const cameraRef = useRef(null);
  const [imageDetails, setImageDetails] = useState({
    images: [],
    imageBlobs: [],
    imageUrls: [],
  });

  const [isLoading, setIsLoading] = useState(false);
  const [addMore, setAddMore] = useState(false);
  const [qrScan, setQrScan] = useState("");
  const [scannedItem, setScannedItem] = useState(null);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [selectTruckModalOpen, setSelectTruckModalOpen] = useState(false);

  // Set Job if unlisted
  const [locations, setLocations] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [jobModalData, setJobModalData] = useState({
    open: false,
    location: null,
    locationId: null,
    search: "",
    imageBlob: null,
    imageUrl: null,
    jobId: null,
    docs: [],
  });

  let defaultConfirmPullData = {
    open: false,
    selectedStatus: null,
    mismatchedItems: [],
    requestMismatchNotes: "",
  };
  const [confirmPullDialogData, setConfirmPullDialogData] = useState(
    defaultConfirmPullData
  );
  const [mismatchReasonText, setMismatchReasonText] = useState(null);

  const { grabFromInventory, grabFromBoneyard, grabFromCommitted, grabs } =
    useContext(GrabsContext);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [category, setCategory] = useState("");
  const [categorySearch, setCategorySearch] = useState("");
  const [categorySearchResults, setCategorySearchResults] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);

  // Menu State
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const upperCaseFirst = (str) => {
    const [x, ...y] = str;
    return [x.toUpperCase(), ...y].join("");
  };

  const getEndpoint = (category) => {
    switch (category) {
      case "Inventory":
        return "/inventory";
      case "Boneyard":
        return "/boneyard";
      case "Committed":
        return "/committed";
      default:
        break;
    }
  };
  const getResponseData = (category, apiResponse) => {
    switch (category) {
      case "Inventory":
        return {
          items: apiResponse.data.materials,
          pages: apiResponse.data.pages,
        };
      case "Boneyard":
        return {
          items: apiResponse.data.materials,
          pages: apiResponse.data.pages,
        };
      case "Committed":
        return {
          items: apiResponse.data.committed.committed,
          pages: apiResponse.data.committed.pages,
        };
      default:
        break;
    }
  };

  const handleCategorySearch = async (controller) => {
    try {
      if (!categorySearch) {
        setCategorySearchResults([]);
        return;
      }
      setIsLoading(true);
      let headers = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      if (controller) {
        headers.signal = controller.signal;
      }
      const repo = getEndpoint(category);
      const query = escapeCharacters(categorySearch);
      const response = await InventoryTracking.get(
        `${repo}?search=${query}&location=${userBranch}&pageSize=20&page=${page}`,
        headers
      );
      const data = getResponseData(category, response);
      console.log(data);
      setIsLoading(false);
      setCategorySearchResults([...data.items]);
      setPages(data.pages);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const getGrabbedDetails = async (controller) => {
    try {
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      if (controller) {
        data.signal = controller.signal;
      }
      const response = await InventoryTracking.get(`/pulls/${id}`, data);
      console.log(response.data);
      setDetails(response.data);
      setImageDetails((prev) => ({
        images: response.data.images,
        imageBlobs: [],
        imageUrls: [],
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const setStatus = async (status, requestMismatchNotes, vehicleId) => {
    try {
      setIsLoading(true);
      const newImages = await uploadImages();
      let images = [...imageDetails.images, ...newImages];
      let allImageCount = images.length;
      if (allImageCount === 0) {
        throw "Image is required (local)";
      } else if (allImageCount > 30) {
        throw "You can only upload up to 30 images";
      }

      const response = await InventoryTracking.put(
        `/pulls/${id}/status`,
        {
          status,
          requestMismatchNotes,
          vehicleId,
          images,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log(response.data);
      openSnackMessage("success", "Successfully updated status");
      setConfirmPullDialogData(defaultConfirmPullData);
      getGrabbedDetails();
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const undoFulfillmentAndResetStatus = async () => {
    try {
      const response = await InventoryTracking.delete(`/pulls/${id}/status`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      openSnackMessage("success", "Successfully updated status");
      setConfirmPullDialogData(defaultConfirmPullData);
      getGrabbedDetails();
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", "Internal Server Error");
      }
    }
  };

  const grabScannedItem = async () => {
    try {
      setIsLoading(true);
      if (scannedItem.source === "inventory") {
        // Add Item To Grabs
        grabFromInventory(scannedItem.details, scannedItem.quantity);
      } else if (scannedItem.source === "boneyard") {
        // Add Item To Grabs
        grabFromBoneyard(scannedItem.details, scannedItem.quantity);
      } else if (scannedItem.source === "committed") {
        // TODO: grab from committed
        grabFromCommitted(scannedItem.details, scannedItem.quantity);
      }
      openSnackMessage("success", "Item Added");
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    } finally {
      setScannedItem(null);
      setQrScan("");
      setIsLoading(false);
    }
  };

  const deletePullRequest = async () => {
    try {
      const response = await InventoryTracking.delete(`/pull-requests/${id}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      openSnackMessage("success", "Pull Deleted");

      navigate(-1);
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", "Internal Server Error");
      }
    }
  };

  const reject = async () => {
    try {
      setIsLoading(true);
      const response = await InventoryTracking.put(
        `/pulls/${id}/reject`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      openSnackMessage("success", "Successfully rejected");
      getGrabbedDetails();
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", "Internal Server Error");
      }
    } finally {
      setIsLoading(false);
    }
  };
  const post = async () => {
    try {
      setIsLoading(true);
      if (!details.project_backlog_id) {
        throw "You must select a job before approving this pull";
        //openSnackMessage("error", "Internal Server Error");
      }
      const response = await InventoryTracking.put(
        `/pulls/${id}/post`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log(response.data);
      openSnackMessage("success", "Successfully posted");
      getGrabbedDetails();
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const unPost = async () => {
    try {
      setIsLoading(true);
      const response = await InventoryTracking.put(
        `/pulls/${id}/un-post`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log(response.data);
      openSnackMessage("success", "Successfully Un-Posted");
      getGrabbedDetails();
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const markAsReviewed = async () => {
    try {
      setIsLoading(true);
      const response = await InventoryTracking.put(
        `/pulls/${id}/reviewed`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log(response.data);
      openSnackMessage("success", "Reviewed");
      getGrabbedDetails();
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const deleteItem = async (itemId) => {
    try {
      const response = await InventoryTracking.delete(
        `/pulls/${id}/items/${itemId}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      openSnackMessage("success", "Pull Updated");

      // Reset Data
      getGrabbedDetails();
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", "Internal Server Error");
      }
    }
  };

  const updateQuantity = async (itemId, quantity) => {
    try {
      const response = await InventoryTracking.put(
        `/pulls/${id}/items/${itemId}/quantity`,
        {
          quantity,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      openSnackMessage("success", "Pull Updated");

      // Reset Data
      getGrabbedDetails();
    } catch (error) {
      //console.log(error);
      Sentry.captureException(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", "Internal Server Error");
      }
    }
  };

  const updateQuantityRequested = async (itemId, quantityRequested) => {
    try {
      const response = await InventoryTracking.put(
        `/pulls/${id}/items/${itemId}/requested-quantity`,
        {
          quantityRequested,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      openSnackMessage("success", "Pull Updated");

      // Reset Data
      getGrabbedDetails();
    } catch (error) {
      //console.log(error);
      Sentry.captureException(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", "Internal Server Error");
      }
    }
  };

  const updatePullJobId = async (jobId) => {
    try {
      const response = await InventoryTracking.put(
        `/pulls/${id}/job/${jobId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      openSnackMessage("success", "Pull Updated");

      // Reset Data
      getGrabbedDetails();
      setJobModalData((prev) => ({ ...prev, open: false }));
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", "Internal Server Error");
      }
    }
  };

  const getJobs = async () => {
    try {
      const response = await InventoryTracking.get(
        `/jobs?search=${jobModalData.search}&location=${jobModalData.location}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log(response.data);
      setJobs(response.data.jobs);
    } catch (error) {
      console.log(error);
    }
  };

  const getLocations = async () => {
    try {
      const response = await InventoryTracking.get(`/locations`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log(response.data);
      setLocations(response.data.locations);
    } catch (error) {
      console.log(error);
    }
  };

  const createGrabItem = (item, origin, quantity) => {
    let grabItem = null;
    if (origin === "INVENTORY") {
      grabItem = {
        quantity: quantity,
        material_origin_key: origin,
        // material_id: item.inventoryId, //reference to: inventory_existence.id
        material_id: item.itemId, // material_id now corresponds with the lookup_inventory_master.id
        item_description: item.description,
        uom: item.uom,
        image_url: item.image_url,
      };
    } else if (origin === "BONEYARD") {
      grabItem = {
        quantity: quantity,
        material_origin_key: origin,
        material_id: item.id, //reference to boneyard_inventory.id (this remains unchanged)
        item_description: item.item,
        uom: item.uom_description,
        image_url: item.image_url,
      };
    } else if (origin === "COMMITTED") {
      grabItem = {
        quantity: quantity,
        material_origin_key: origin,
        // material_id: item.id, //reference to material_tracking_committed.id
        material_id: item.item_id, // material_id now corresponds with the lookup_inventory_master.id
        item_description: item.item_description,
        uom: item.uom,
        image_url: item.image_url,
      };
    } else if (origin === "STAGED") {
      grabItem = {
        quantity: item.quantity,
        material_origin_key: origin,
        material_id: item.item_id, // material_id now corresponds with the lookup_inventory_master.id
        item_description: item.description,
        uom: item.uom,
        image_url: item.image_url,
        staged_id: item.staged_id,
        // original_staged_transaction: item.txn,
        reference_id: item.txn,
        staged_source: item.material_origin_key,
      };
    }

    return grabItem;
  };

  const getItemDetailFromScan = async (scannedUrl) => {
    try {
      setIsLoading(true);

      if (scannedUrl.includes("/jobs/")) {
        let jobId = scannedUrl.split("/jobs/")[1];
      } else {
        // Parse Item QR Code URL
        let itemUri;
        let itemId;
        if (scannedUrl.includes("/inventory/")) {
          itemUri = "inventory";
          itemId = scannedUrl.split("/inventory/")[1];
        } else if (scannedUrl.includes("/boneyard/")) {
          itemUri = "boneyard";
          itemId = scannedUrl.split("/boneyard/")[1];
        } else if (scannedUrl.includes("/committed/")) {
          itemUri = "committed";
          itemId = scannedUrl.split("/committed/")[1];
        }

        console.log(`getting item /${itemUri}/${itemId}`);
        // Get Item Details
        const response = await InventoryTracking.get(`/${itemUri}/${itemId}`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        let material = response.data.material;
        console.log(material);

        // Set Modal Data
        if (scannedUrl.includes("/inventory/")) {
          setScannedItem({
            source: itemUri,
            details: material,
            name: material.description,
            image: material.image_url,
            uom: material.uom,
            quantity: 1,
          });
        } else if (scannedUrl.includes("/boneyard/")) {
          setScannedItem({
            source: itemUri,
            details: material,
            name: material.item,
            image: material.image_url,
            uom: material.uom,
            quantity: 1,
          });
        } else if (scannedUrl.includes("/committed/")) {
          setScannedItem({
            source: itemUri,
            details: material,
            name: material.item_description,
            image: material.image_url,
            uom: material.uom,
            quantity: 1,
          });
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const addItem = async (item, type) => {
    console.log("addItem ", item, type);
    try {
      setIsLoading(true);
      let pulledItem;
      let itemId;
      switch (type) {
        case "Inventory":
          itemId = item.inventoryId;
          break;
        case "Committed":
          itemId = item.id;
          break;
        case "Boneyard":
          itemId = item.id;
          break;

        default:
          break;
      }

      const response = await InventoryTracking.get(`/${type}/${itemId}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      let material = response.data.material;
      console.log("MATERIAL: ", material);

      // Set Modal Data
      if (type === "Inventory") {
        pulledItem = createGrabItem(material, "INVENTORY", 1);
      } else if (type === "Boneyard") {
        pulledItem = createGrabItem(material, "BONEYARD", 1);
      } else if (type === "Committed") {
        if (details.project_backlog_id !== item.project_backlog_id) {
          openSnackMessage(
            "warning",
            `
                    This material is committed to ${item.job_name} 
                    (${item.job_id}). You are currently stocking out
                    materials committed to
                    ${details.job_name} (${details.job_id}).
                    You must remove the committed materials before
                    stocking out materials committed to another job.
                  `
          );
          return;
        } else {
          pulledItem = createGrabItem(material, "COMMITTED", 1);
        }
      }
      const addPulledItem = await InventoryTracking.post(
        `/stockouts/${details.id}/items`,
        pulledItem,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      openSnackMessage("success", "Item Added!");
      getGrabbedDetails();
      setScannedItem(null);
      setQrScan("");
    } catch (error) {
      Sentry.captureException(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.message);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", "Internal Server Error");
      }
    } finally {
      setIsLoading(false);
      setScannedItem(null);
      setQrScan("");
    }
  };

  // Uploads an Array Of Images
  // Returns an Array of Entity Image object with id, entity_type, image_url
  const uploadImages = async () => {
    let imageFormDataArray = [];

    let x = Math.ceil(imageDetails.imageBlobs.length / 15);
    console.log("x = ", x);
    for (let i = 0; i < x; i++) {
      let imgFormData = new FormData();
      for (
        let j = 15 * i;
        j < 15 * (i + 1) && j < imageDetails.imageBlobs.length;
        j++
      ) {
        imgFormData.append("images", imageDetails.imageBlobs[j]);
      }
      console.log(imgFormData);
      imageFormDataArray.push(imgFormData);
    }
    console.log("imageFormDataArray", imageFormDataArray);
    let imageUrls = [];
    for (let data of imageFormDataArray) {
      data.append("resourceType", "public/pull");
      let response = await InventoryTracking.post(`/uploads/images`, data, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "content-type": "multipart/form-data",
        },
      });
      imageUrls = [...imageUrls, ...response.data.files];
    }
    console.log("imageUrls uploaded", imageUrls);
    let images = [];
    for (let img of imageUrls) {
      images.push({
        image_url: img.url,
      });
    }
    return images;
  };

  const onPressLoadedWithPreSelectedTruck = async (transfer_vehicle_id) => {
    try {
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      const result = await InventoryTracking.get(
        `/vehicles/${transfer_vehicle_id}`,
        data
      );

      let vehicle = result.data.vehicle;

      if (vehicle) {
        onConfirmPull("PULLLOADED", vehicle);
      } else {
        setSelectTruckModalOpen(true);
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
      openSnackMessage("error", error);
    }
  };

  const onConfirmPull = (pullStatus, vehicle) => {
    // Check if any item quantities have deviated
    let mismatchedItems = [];
    for (const item of details.items) {
      if (item.requested_quantity != item.quantity) mismatchedItems.push(item);
    }

    setConfirmPullDialogData({
      open: true,
      selectedStatus: pullStatus,
      mismatchedItems: mismatchedItems,
      requestMismatchNotes: "",
      vehicle,
    });
  };

  const onCloseConfirmPullDialog = () => {
    setConfirmPullDialogData({
      open: false,
      selectedStatus: null,
      mismatchedItems: [],
      requestMismatchNotes: "",
    });
  };

  const handleFileSelect = async (event) => {
    const originalFiles = event.target.files;
    let compressedFiles = [];
    let compressedFileUrls = [];
    for (const originalFile of originalFiles) {
      const originalFileUrl = URL.createObjectURL(originalFile);
      console.log("originalFile", originalFile);
      console.log("originalFileURL", originalFileUrl);
      console.log("originalFile instanceof Blob", originalFile instanceof Blob); // true
      console.log(`originalFile size ${originalFile.size / 1024 / 1024} MB`);

      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };

      // Compress File (returns Blob instead of File)
      let compressedBlob = await imageCompression(originalFile, options);
      // Convert Compressed Blob back to File
      let compressedFile = new File([compressedBlob], originalFile.name, {
        type: originalFile.type,
      });
      const compressedFileUrl = URL.createObjectURL(compressedFile);

      console.log(
        `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
      );
      console.log("compressedFile", compressedFile);
      console.log("compressedFileURL", compressedFileUrl);
      compressedFiles.push(compressedFile);
      compressedFileUrls.push(compressedFileUrl);
    }

    setImageDetails((prev) => ({
      ...prev,
      imageBlobs: [...prev.imageBlobs, ...compressedFiles],
      imageUrls: [...prev.imageUrls, ...compressedFileUrls],
    }));
  };

  useEffect(() => {
    const controller = new AbortController();
    setTimeout(() => {
      handleCategorySearch(controller);
      setPage(1);
    }, 300);
    return () => {
      controller.abort();
    };
  }, [categorySearch, category]);

  useEffect(() => {
    const controller = new AbortController();

    getGrabbedDetails(controller);
    return () => {
      controller.abort();
    };
  }, [id]);

  useEffect(() => {
    if (locations.length === 0) {
      getLocations();
    }
  }, []);

  useEffect(() => {
    getJobs();
  }, [jobModalData]);

  useEffect(() => {
    console.log("qrScan", qrScan);
    if (qrScan) {
      // Get Item Details from qr scan link
      // Add to grabs
      getItemDetailFromScan(qrScan);
    }
  }, [qrScan]);

  const isPullRequest = !!details?.is_stage_request;
  const isApprovedOrRejected = !!details?.approved_by || !!details?.rejected_by;

  return (
    <PageContent>
      {details ? (
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <div>
              <h2
                style={{
                  textAlign: "center",
                  marginBottom: "0",
                }}
              >
                {details.is_stage_request ? "PULL REQUEST" : "STOCK OUT"}{" "}
                {/* {details.rejected_by ? "(REJECTED)" : ""}
                {details.approved_by ? "(POSTED)" : ""} */}
              </h2>
              {details.status && (
                <Typography textAlign="center" variant="subtitle1">
                  {`(${details.status_description})`}
                </Typography>
              )}
            </div>
            {details.is_stage_request && (
              <Box sx={{ position: "absolute", right: 0, top: 16 }}>
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={open ? "long-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <MoreVert />
                </IconButton>
                <Menu
                  id="fade-menu"
                  MenuListProps={{
                    "aria-labelledby": "fade-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  TransitionComponent={Fade}
                >
                  {details.is_stage_request && (
                    <MenuItem
                      onClick={() => {
                        if (details.is_stage_request)
                          navigate(`/pull-requests/${details.id}/print`);
                        else navigate(`/daily-stockouts/${details.id}/print`);
                      }}
                    >
                      <Print color="primary" />
                      Print
                    </MenuItem>
                  )}
                  {!details.status && isMaterialAppAdmin() && (
                    <MenuItem
                      onClick={() => {
                        setDeleteDialog(true);
                      }}
                    >
                      <Delete color="error" />
                      Delete
                    </MenuItem>
                  )}
                </Menu>
              </Box>
            )}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            ID: {details.transactionid}
          </div>
          {details.is_stage_request ? (
            <>
              <div style={{}}>
                Requested by: {details.user_full_name} on{" "}
                {momentPhoenix(details.created_on).format("MM/DD/YY")}
              </div>
              <div style={{}}>
                Fulfilled by: {details.transferred_by_full_name}{" "}
                {details.transferred_by_full_name && details.created_on
                  ? `on 
                ${momentPhoenix(details.created_on).format("MM/DD/YY")}`
                  : ""}
              </div>
            </>
          ) : (
            <div style={{}}>
              Stocked Out By: {details.transferred_by_full_name} on{" "}
              {momentPhoenix(details.created_on).format("MM/DD/YY")}
            </div>
          )}

          <div
            style={{
              position: "relative",
              flexDirection: "row",
              display: isSmallScreen ? "column" : "row",
            }}
          >
            <div
              style={{
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                height: isSmallScreen && !details.job_name ? "100%" : 50,
              }}
              onClick={() => {
                if (details.project_backlog_id)
                  navigate(`/jobs/${details.project_backlog_id}`);
              }}
            >
              Job:{" "}
              {details.job_name
                ? `${details.projectid} - ${details.job_name}`
                : "[UNLISTED]"}
            </div>
            {!details.rejected_by && !details.approved_by && (
              <Button
                style={{
                  position: isSmallScreen ? "relative" : "absolute",
                  right: 0,
                  top: 0,
                  width: isSmallScreen ? "100%" : 150,
                  height: 30,
                  margin: 0,
                }}
                variant="contained"
                onClick={() => {
                  setJobModalData((prev) => ({ ...prev, open: true }));
                }}
              >
                {details.job_name ? "Change Job" : "Select Job"}
              </Button>
            )}
            <Modal
              open={jobModalData.open}
              onClose={() => {
                setJobModalData((prev) => ({ ...prev, open: false }));
              }}
            >
              <Card
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  minWidth: 300,
                  maxWidth: 500,
                  maxHeight: "100%",
                  bgcolor: "background.paper",
                  boxShadow: 24,
                  p: 4,
                  display: "block",
                  overflow: "scroll",
                  overflowX: "hidden",
                }}
              >
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Set Job
                </Typography>
                <FormControl fullWidth style={{ marginTop: 16 }}>
                  <InputLabel>Location</InputLabel>
                  <Select
                    value={jobModalData.location}
                    label="Location"
                    onChange={(e) => {
                      let selectedLocation = locations.find(
                        (element) => element.location === e.target.value
                      );
                      setJobModalData((prev) => ({
                        ...prev,
                        location: selectedLocation.location,
                        locationId: selectedLocation.locationid,
                        jobId: null,
                      }));
                    }}
                  >
                    {locations.map((location, index) => (
                      <MenuItem key={index} value={location.location}>
                        {location.location}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FilledInput
                  style={{ marginTop: 16, width: "100%" }}
                  type="text"
                  hiddenLabel={true}
                  value={jobModalData.search}
                  placeholder="Search Job Name"
                  onChange={(e) => {
                    setJobModalData((prev) => ({
                      ...prev,
                      search: e.target.value,
                    }));
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <Search />
                    </InputAdornment>
                  }
                />
                <div
                  style={{
                    display: "block",
                    maxHeight: 200,
                    maxWidth: "100%",
                    background: "#d2d2d2",
                    overflow: "scroll",
                    overflowX: "hidden",
                  }}
                >
                  {jobs.map((job, index) => (
                    <div
                      style={{
                        padding: 8,
                        maxWidth: "100%",
                        background:
                          job.id === jobModalData.jobId ? "#000" : null,
                        color: job.id === jobModalData.jobId ? "#fff" : null,
                      }}
                      key={index}
                      onClick={() => {
                        setJobModalData((prev) => ({
                          ...prev,
                          jobId: job.id,
                        }));
                      }}
                    >
                      <p
                        style={{ margin: 0, fontWeight: "bold", fontSize: 16 }}
                      >
                        {job.projectid} - {job.job_name}
                      </p>
                      <p
                        style={{
                          margin: 0,
                          fontSize: 12,
                          color:
                            job.id === jobModalData.jobId
                              ? "#fff"
                              : "rgba(0, 0, 0, 0.7)",
                        }}
                      >
                        {job.address}
                      </p>
                    </div>
                  ))}
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    disabled={jobModalData.jobId === null}
                    variant="outlined"
                    onClick={() => {
                      updatePullJobId(jobModalData.jobId);
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </Card>
            </Modal>
          </div>
          {details.job_address && (
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href={`http://maps.google.com/?q=${encodeURIComponent(
                details.job_address
              )}`}
              style={{
                display: "flex",
                alignItems: "center",
                height: isSmallScreen && !details.job_name ? "100%" : 50,
              }}
            >
              Job Address: {details.job_address}
            </Link>
          )}
          {(details.instructions ||
            details.notes ||
            details.request_mismatch_notes) && (
            <Card
              style={{
                marginTop: 8,
                padding: 16,
                borderRadius: 4,
                fontWeight: "bold",
                backgroundColor: theme.palette.secondary.light,
              }}
            >
              {details.instructions && (
                <div style={{}}>
                  Instructions: {details.instructions}{" "}
                  {details.transfer_vehicle_id && !details.status_description
                    ? `#${details.transfer_vehicle_id}`
                    : ""}
                </div>
              )}
              {details.jobsite_delivery_instructions && (
                <div style={{}}>
                  Jobsite Delivery Instructions:{" "}
                  {details.jobsite_delivery_instructions}
                </div>
              )}

              {details.notes && <div style={{}}>Notes: {details.notes}</div>}
              {details.request_mismatch_notes && (
                <div style={{ fontStyle: "italic" }}>
                  Quantity Mismatch Notes: {details.request_mismatch_notes}
                </div>
              )}
            </Card>
          )}
          {details.status &&
            isMaterialAppAdmin() &&
            !details.approved_by &&
            !details.rejected_by && (
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <EditPulledItems
                  details={details}
                  onUpdatePulledItems={() => {
                    getGrabbedDetails();
                  }}
                  isPullRequest={isPullRequest}
                  isApprovedOrRejected={isApprovedOrRejected}
                />
              </Box>
            )}

          {details.items.map((item, i) => (
            <PulledItemCard
              key={i}
              showRequested={details.is_stage_request}
              itemName={item.item_description}
              internalCode={item.internal_code}
              source={item.material_origin_key}
              qtyReq={item.requested_quantity}
              qtyPulled={item.quantity}
              uom={item.uom}
              onQtyChange={(qty) => {
                updateQuantity(item.id, qty);
              }}
              onReqQtyChange={(qty) => {
                updateQuantityRequested(item.id, qty);
              }}
              onRemove={() => deleteItem(item.id)}
              disableDelete={
                (!isPullRequest && !isMaterialAppAdmin()) ||
                isApprovedOrRejected ||
                details.status_description
                  ? true
                  : (isPullRequest && !isMaterialAppAdmin()) ||
                    isApprovedOrRejected
                  ? true
                  : false
              }
              disableEdit={
                isApprovedOrRejected ||
                (!isPullRequest && !isMaterialAppAdmin()) ||
                details.status_description
              }
              enableEditRequestedQty={
                !isApprovedOrRejected && isPullRequest && isMaterialAppAdmin()
              }
            />
          ))}
          {!details.status_description &&
          !details.approved_by &&
          !details.rejected_by &&
          isMaterialAppAdmin ? (
            // (isMaterialAppAdmin() || details.is_stage_request)
            <div style={{ marginTop: 16 }}>
              {!addMore ? (
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={() => setAddMore(true)}
                >
                  + MATERIAL
                </Button>
              ) : (
                <div>
                  <CategorySearch
                    categoryOptions={["Inventory", "Committed", "Boneyard"]}
                    categoryValue={category}
                    searchValue={categorySearch}
                    results={categorySearchResults}
                    handleResultClick={(item) => {
                      addItem(item, category);
                      setCategorySearch("");
                    }}
                    handleSearch={(e) => setCategorySearch(e.target.value)}
                    handleSelect={(e) => setCategory(e.target.value)}
                    onQrCodeScan={(result) => {
                      setQrScan(result);
                    }}
                  />
                </div>
              )}
            </div>
          ) : null}
          {/* Add Image(s) Section - At least 1 image required */}
          <div>
            <h4>Images</h4>
            {!details.status_description && (
              <Typography
                id="modal-modal-title"
                variant="p"
                component="p"
                sx={{ margin: 0 }}
              >
                At least one image is required. Maximum 30 images
              </Typography>
            )}

            <div
              style-={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Grid
                container
                padding={0}
                spacing={0}
                alignItems="center"
                justifyContent="center"
              >
                {
                  //Saved Images
                  imageDetails &&
                    imageDetails.images &&
                    imageDetails.images.map((entity_image, index) => (
                      <div
                        key={index}
                        style={{
                          position: "relative",
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        <img
                          onClick={() => {
                            window.open(
                              entity_image.image_url,
                              "_blank",
                              "noopener,noreferrer"
                            );
                          }}
                          src={entity_image.image_url}
                          style={{
                            cursor: "pointer",
                            minHeight: "100px",
                            maxHeight: "100px",
                            minWidth: "100px",
                            maxWidth: "100px",
                            marginRight: "3px",
                            border: "1px solid black",
                          }}
                        />
                        {!details.status_description && (
                          <Close
                            color="error"
                            style={{
                              position: "absolute",
                              top: 8,
                              right: 8,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              let imagesCopy = imageDetails.images;
                              imagesCopy.splice(index, 1);
                              setImageDetails((prev) => ({
                                ...prev,
                                images: imagesCopy,
                              }));
                            }}
                          />
                        )}
                      </div>
                    ))
                }
                {
                  //Unsaved Images
                  imageDetails &&
                    imageDetails.imageUrls &&
                    imageDetails.imageUrls.length > 0 &&
                    imageDetails.imageUrls.map((url, index) => (
                      <div
                        key={index}
                        style={{
                          position: "relative",
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        <img
                          src={url}
                          style={{
                            minHeight: "100px",
                            maxHeight: "100px",
                            minWidth: "100px",
                            maxWidth: "100px",
                            marginRight: "3px",
                            border: "1px solid black",
                          }}
                        />
                        <Close
                          color="error"
                          style={{
                            position: "absolute",
                            top: 8,
                            right: 8,
                          }}
                          onClick={() => {
                            // Remove unsaved image from imageBlobs and imageUrls arrays
                            let imageBlobsCopy = imageDetails.imageBlobs;
                            let imageUrlsCopy = imageDetails.imageUrls;
                            imageBlobsCopy.splice(index, 1);
                            imageUrlsCopy.splice(index, 1);
                            setImageDetails((prev) => ({
                              ...prev,
                              imageBlobs: imageBlobsCopy,
                              imageUrls: imageUrlsCopy,
                            }));
                          }}
                        />
                      </div>
                    ))
                }
              </Grid>
              {!details.status_description && (
                <div
                  style={{
                    display: "flex",
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    marginTop: "4px",
                  }}
                >
                  <label>
                    <input
                      ref={uploadRef}
                      multiple
                      style={{ display: "none" }}
                      type="file"
                      accept="image/*"
                      name="images"
                      // capture="environment"
                      onChange={(event) => {
                        handleFileSelect(event);
                      }}
                    />
                    <Button
                      size="small"
                      variant="contained"
                      onClick={() => {
                        uploadRef.current.click();
                      }}
                    >
                      <FileUpload />
                      Upload
                    </Button>
                  </label>
                  <label>
                    <input
                      ref={cameraRef}
                      multiple
                      style={{ display: "none" }}
                      type="file"
                      accept="image/*"
                      name="images"
                      capture="environment"
                      onChange={(event) => {
                        handleFileSelect(event);
                      }}
                    />
                    <Button
                      size="small"
                      variant="contained"
                      sx={{ marginLeft: "8px" }}
                      onClick={() => {
                        cameraRef.current.click();
                      }}
                    >
                      <AddAPhoto sx={{ marginRight: "4px" }} /> Capture
                    </Button>
                  </label>
                </div>
              )}
            </div>
          </div>
          {!details.rejected_by && !details.approved_by && (
            <div
              style={{
                // marginTop: "20px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                margin: "20px 10px 0 10px",
              }}
            >
              {!details.status ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Dialog
                    fullScreen={
                      isSmallScreen &&
                      confirmPullDialogData.mismatchedItems.length > 0
                    }
                    open={confirmPullDialogData.open}
                    onClose={onCloseConfirmPullDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    {/* TITLE */}
                    <DialogTitle id="alert-dialog-title">
                      Confirm{" "}
                      {confirmPullDialogData.selectedStatus === "PULLDELIVERED"
                        ? "Pulled & Delivered"
                        : confirmPullDialogData.selectedStatus === "PULLLOADED"
                        ? "Pulled & On Truck"
                        : confirmPullDialogData.selectedStatus === "PULLSTAGED"
                        ? "Pulled & Staged"
                        : ""}
                    </DialogTitle>

                    {/* CONFIRM MISMATCH DIALOG */}
                    {confirmPullDialogData.mismatchedItems.length > 0 &&
                    !confirmPullDialogData.requestMismatchNotes ? (
                      <>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            Pulled items do not match the requested quantities:
                          </DialogContentText>
                          <Box sx={{}}>
                            {confirmPullDialogData.mismatchedItems.map(
                              (item, i) => (
                                <PulledItemCard
                                  key={i}
                                  showRequested={true}
                                  itemName={item.item_description}
                                  source={item.material_origin_key}
                                  qtyReq={item.requested_quantity}
                                  qtyPulled={item.quantity}
                                  uom={item.uom}
                                  disableDelete={true}
                                  disableEdit={true}
                                  enableEditRequestedQty={false}
                                />
                              )
                            )}
                          </Box>
                          <Box
                            sx={{
                              width: "100%",
                              paddingLeft: "1px",
                              marginTop: "12px",
                            }}
                          >
                            <TextareaAutosize
                              placeholder="State the reason(s) here to confirm"
                              minRows={4}
                              style={{
                                width: "100%",
                                background: isDarkMode
                                  ? "#232323"
                                  : theme.palette.primary.extraDark,
                                color: theme.palette.secondary.contrastText,
                                borderColor: theme.palette.primary.main,
                              }}
                              value={mismatchReasonText}
                              onChange={(e) => {
                                // setConfirmPullDialogData((prev) => ({
                                //   ...prev,
                                //   requestMismatchNotes: e.target.value,
                                // }));
                                setMismatchReasonText(e.target.value);
                              }}
                            />
                          </Box>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={onCloseConfirmPullDialog}>
                            Cancel
                          </Button>
                          <Button
                            disabled={
                              confirmPullDialogData.mismatchedItems.length >
                                0 && !mismatchReasonText
                            }
                            onClick={() => {
                              setConfirmPullDialogData((prev) => ({
                                ...prev,
                                requestMismatchNotes: mismatchReasonText,
                              }));
                            }}
                            autoFocus
                          >
                            Continue
                          </Button>
                        </DialogActions>
                      </>
                    ) : (
                      // NO MISMATCH, CONFIRM DIALOG
                      <>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            Confirm that all materials requested are{" "}
                            {confirmPullDialogData.selectedStatus ===
                            "PULLDELIVERED"
                              ? "Pulled & Delivered"
                              : confirmPullDialogData.selectedStatus ===
                                "PULLLOADED"
                              ? "Pulled & On Truck"
                              : confirmPullDialogData.selectedStatus ===
                                "PULLSTAGED"
                              ? "Pulled & Staged"
                              : ""}
                          </DialogContentText>
                          {confirmPullDialogData.vehicle && (
                            <DialogContentText>
                              Truck Loaded: Truck{" "}
                              {confirmPullDialogData.vehicle.id}
                              <Button
                                onClick={() => {
                                  setSelectTruckModalOpen(true);
                                }}
                              >
                                (edit)
                              </Button>
                            </DialogContentText>
                          )}
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={onCloseConfirmPullDialog}>
                            Cancel
                          </Button>
                          <Button
                            onClick={() => {
                              onCloseConfirmPullDialog();
                              setConfirmPullModal(true);
                            }}
                          >
                            Other
                          </Button>
                          {confirmPullDialogData.mismatchedItems.length > 0 &&
                            confirmPullDialogData.requestMismatchNotes && (
                              <Button
                                onClick={() => {
                                  setConfirmPullDialogData((prev) => ({
                                    ...prev,
                                    requestMismatchNotes: null,
                                  }));
                                }}
                              >
                                Back
                              </Button>
                            )}
                          <Button
                            disabled={
                              confirmPullDialogData.mismatchedItems.length >
                                0 && !confirmPullDialogData.requestMismatchNotes
                            }
                            onClick={() => {
                              let vehicleId = confirmPullDialogData.vehicle
                                ? confirmPullDialogData.vehicle.id
                                : null;

                              setStatus(
                                confirmPullDialogData.selectedStatus,
                                confirmPullDialogData.requestMismatchNotes,
                                vehicleId
                              );
                            }}
                            autoFocus
                          >
                            Confirm
                          </Button>
                        </DialogActions>
                      </>
                    )}
                  </Dialog>
                  <Dialog
                    fullWidth
                    fullScreen={isSmallScreen}
                    open={confirmPullModal}
                    onClose={() => {
                      setConfirmPullModal(false);
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    {/* TITLE */}
                    <DialogTitle id="alert-dialog-title">
                      Fulfill Pull Request
                    </DialogTitle>

                    <>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          Select Fulfillment Status
                        </DialogContentText>
                        <Box
                          sx={{
                            marginTop: "16px",
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Button
                            style={{ width: 100, height: 100 }}
                            disabled={isLoading}
                            variant="contained"
                            onClick={() => {
                              setConfirmPullModal(false);
                              onConfirmPull("PULLSTAGED");
                            }}
                          >
                            Pulled & Staged
                          </Button>
                          <Button
                            style={{ width: 100, height: 100, marginLeft: 4 }}
                            disabled={isLoading}
                            variant="contained"
                            onClick={() => {
                              setConfirmPullModal(false);
                              // onConfirmPull("PULLLOADED");
                              if (details.transfer_vehicle_id) {
                                onPressLoadedWithPreSelectedTruck(
                                  details.transfer_vehicle_id
                                );
                              } else {
                                setSelectTruckModalOpen(true);
                              }
                            }}
                          >
                            Pulled & On Truck
                          </Button>
                          <Button
                            style={{ width: 100, height: 100, marginLeft: 4 }}
                            disabled={isLoading}
                            variant="contained"
                            onClick={() => {
                              setConfirmPullModal(false);
                              onConfirmPull("PULLDELIVERED");
                            }}
                          >
                            Pulled & Delivered
                          </Button>
                        </Box>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={() => {
                            setConfirmPullModal(false);
                          }}
                        >
                          Cancel
                        </Button>
                      </DialogActions>
                    </>
                  </Dialog>
                  <Button
                    color="success"
                    style={{ width: 200, height: 70 }}
                    disabled={isLoading}
                    variant="contained"
                    onClick={() => {
                      if (details.instructions) {
                        if (details.instructions.includes("Stage")) {
                          onConfirmPull("PULLSTAGED"); // depending on instruction
                        } else if (details.instructions.includes("Load")) {
                          // onConfirmPull("PULLLOADED"); // depending on instruction
                          if (details.transfer_vehicle_id) {
                            onPressLoadedWithPreSelectedTruck(
                              details.transfer_vehicle_id
                            );
                          } else {
                            setSelectTruckModalOpen(true);
                          }
                        } else if (details.instructions.includes("Deliver")) {
                          onConfirmPull("PULLDELIVERED"); // depending on instruction
                        }
                      } else {
                        setConfirmPullModal(true);
                      }
                    }}
                  >
                    Fulfill
                  </Button>
                </div>
              ) : role && role.toLowerCase() === "admin" ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {isPullRequest && (
                    <LoadingButton
                      loading={isLoading}
                      style={{ width: 100, height: 50, marginLeft: 12 }}
                      variant="contained"
                      onClick={() => {
                        undoFulfillmentAndResetStatus();
                      }}
                    >
                      Reset
                    </LoadingButton>
                  )}

                  <LoadingButton
                    loading={isLoading}
                    style={{ width: 100, height: 50, marginLeft: 12 }}
                    variant="contained"
                    onClick={() => {
                      reject();
                    }}
                  >
                    Reject
                  </LoadingButton>
                  <LoadingButton
                    color="success"
                    loading={isLoading}
                    style={{ width: 100, height: 50, marginLeft: 12 }}
                    variant="contained"
                    onClick={() => {
                      post();
                    }}
                  >
                    Post
                  </LoadingButton>
                </div>
              ) : null}
            </div>
          )}
          {details.approved_by && role && role.toLowerCase() === "admin" && (
            <div
              style={{
                // marginTop: "20px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                margin: "20px 10px 0 10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <LoadingButton
                  color="error"
                  loading={isLoading}
                  style={{ width: 100, height: 50, marginLeft: 12 }}
                  variant="contained"
                  onClick={() => {
                    unPost();
                  }}
                >
                  Undo Post
                </LoadingButton>
                {/* {!details.is_reviewed && (
                  <LoadingButton
                    color="success"
                    loading={isLoading}
                    style={{ width: 100, height: 50, marginLeft: 12 }}
                    variant="contained"
                    onClick={() => {
                      markAsReviewed();
                    }}
                  >
                    Mark as Reviewed
                  </LoadingButton>
                )} */}
              </div>
            </div>
          )}
          <Modal
            open={scannedItem !== null}
            onClose={() => {
              setScannedItem(null);
              setQrScan("");
            }}
          >
            <Card
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                minWidth: 300,
                maxWidth: 500,
                maxHeight: "100%",
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                display: "block",
                overflow: "scroll",
                overflowX: "hidden",
              }}
            >
              {scannedItem ? (
                <div style={{ flex: 1 }}>
                  <h3>Pull from {scannedItem.source}</h3>
                  {!isSmallScreen && (
                    <div
                      style={{
                        borderRadius: 0,
                        marginTop: 1,
                        display: "flex",
                        flexDirection: "row",
                        padding: 8,
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          width: 100,
                          paddingLeft: 12,
                          paddingRight: 12,
                          fontSize: 12,
                        }}
                      >
                        <p
                          style={{
                            margin: 0,
                            fontWeight: "bold",
                            //textAlign: "center",
                          }}
                        >
                          Image
                        </p>
                      </div>
                      <div style={{ flex: 1, fontSize: 12 }}>
                        <p
                          style={{
                            margin: 0,
                            fontWeight: "bold",
                            //textAlign: "center",
                          }}
                        >
                          Description
                        </p>
                      </div>
                      <div style={{ minWidth: 80, fontSize: 12 }}>
                        <p
                          style={{
                            margin: 0,
                            fontWeight: "bold",
                            textAlign: "center",
                          }}
                        >
                          UOM
                        </p>
                      </div>
                      <div style={{ minWidth: 100, fontSize: 12 }}>
                        <p
                          style={{
                            margin: 0,
                            fontWeight: "bold",
                            textAlign: "center",
                          }}
                        >
                          Quantity
                        </p>
                      </div>
                    </div>
                  )}
                  {isSmallScreen ? (
                    <div style={{ padding: 8, marginTop: 4 }}>
                      <div
                        style={{
                          flex: 1,
                          fontSize: 12,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          position: "relative",
                        }}
                      >
                        <div style={{ width: 100, padding: 12 }}>
                          <img
                            src={scannedItem.image}
                            style={{ width: "100%", aspectRatio: 15 / 20 }}
                          />
                        </div>
                        <p style={{ flex: 1, margin: 0 }}>{scannedItem.name}</p>
                      </div>

                      <div
                        style={{
                          borderRadius: 0,
                          marginTop: 8,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                        }}
                      >
                        <div
                          style={{
                            flex: 1,
                            fontSize: 12,
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                          }}
                        >
                          <p
                            style={{ margin: 0, padding: 8, textAlign: "end" }}
                          >
                            {scannedItem.uom}
                          </p>
                        </div>
                        <div
                          style={{
                            flex: 1,
                          }}
                        >
                          <TextField
                            label="Quantity"
                            type="number"
                            value={scannedItem.quantity}
                            onChange={(e) => {
                              let val;
                              if (
                                e.target.value == "" ||
                                e.target.value === undefined ||
                                e.target.value === null ||
                                e.target.value === "0" ||
                                e.target.value === "00"
                              ) {
                                val = 0;
                              } else {
                                val = e.target.value.replace(/^0+/, "");
                                val = toFixedIfNecessary(val, 2);
                              }
                              // Do Not Allow Negative
                              if (val < 0) {
                                val = 0;
                              }
                              setScannedItem((prev) => ({
                                ...prev,
                                quantity: val,
                              }));
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        borderRadius: 0,
                        marginTop: 1,
                        display: "flex",
                        flexDirection: "row",
                        padding: 8,
                        position: "relative",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ width: 100, padding: 12 }}>
                        <img
                          src={scannedItem.image}
                          style={{ width: "100%", aspectRatio: 15 / 20 }}
                        />
                      </div>
                      <div
                        style={{
                          flex: 1,
                          fontSize: 12,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <p style={{ flex: 1, margin: 0 }}>{scannedItem.name}</p>
                      </div>
                      <div
                        style={{
                          width: 100,
                          fontSize: 12,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <p style={{ margin: 0, textAlign: "center" }}>
                          {scannedItem.uom}
                        </p>
                      </div>
                      <TextField
                        style={{
                          width: 80,
                        }}
                        label="Quantity"
                        type="number"
                        value={scannedItem.quantity}
                        onChange={(e) => {
                          let val;
                          if (
                            e.target.value == "" ||
                            e.target.value === undefined ||
                            e.target.value === null ||
                            e.target.value === "0" ||
                            e.target.value === "00"
                          ) {
                            val = 0;
                          } else {
                            val = e.target.value.replace(/^0+/, "");
                            val = toFixedIfNecessary(val, 2);
                          }
                          // Do Not Allow Negative
                          if (val < 0) {
                            val = 0;
                          }
                          setScannedItem((prev) => ({
                            ...prev,
                            quantity: val,
                          }));
                        }}
                      />
                    </div>
                  )}
                  {scannedItem.source === "committed" &&
                    grabs.committed.length == 0 &&
                    grabs.selectedJob && (
                      <div>
                        Current Quick Pull is set for{" "}
                        {grabs.selectedJob.jobName} (
                        {grabs.selectedJob.projectId}). Pulling committed
                        material will set the selected job to{" "}
                        {scannedItem.details.job_name} (
                        {scannedItem.details.job_id}
                        ).
                      </div>
                    )}
                  {scannedItem.source === "committed" &&
                    grabs.committed.length > 0 &&
                    grabs.selectedJob.projectId !==
                      scannedItem.details.job_id && (
                      <div>
                        This material is committed to{" "}
                        {scannedItem.details.job_name} (
                        {scannedItem.details.job_id}). You are currently pulling
                        materials committed to or staged for{" "}
                        {grabs.selectedJob.jobName} (
                        {grabs.selectedJob.projectId}). You must remove the
                        committed and staged materials before pulling materials
                        committed to another job.
                      </div>
                    )}
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      style={{ width: 100, marginRight: 8 }}
                      variant="outlined"
                      onClick={() => {
                        setScannedItem(null);
                        setQrScan("");
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={
                        scannedItem.source === "committed" &&
                        grabs.committed.length > 0 &&
                        grabs.selectedJob.projectId !==
                          scannedItem.details.job_id
                      }
                      style={{ width: 100 }}
                      variant="contained"
                      onClick={() => {
                        console.log("Submit");
                        // grabScannedItem();
                        addItem(
                          scannedItem.details,
                          upperCaseFirst(scannedItem.source)
                        );
                      }}
                    >
                      Add
                    </Button>
                  </div>
                </div>
              ) : null}
            </Card>
          </Modal>
          <SelectTruckModal
            open={selectTruckModalOpen}
            setOpen={setSelectTruckModalOpen}
            onSelect={(vehicleId, vehicle) => {
              setSelectTruckModalOpen(false);
              onConfirmPull("PULLLOADED", vehicle);
            }}
          />

          {!details.status && isMaterialAppAdmin() && (
            <Dialog
              open={deleteDialog}
              onClose={() => {
                setDeleteDialog(false);
              }}
            >
              <DialogTitle>Delete Pull Request</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Are you sure you want to delete {details.transactionid}?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setDeleteDialog(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    deletePullRequest();
                    setDeleteDialog(false);
                  }}
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </div>
      ) : (
        <div style={{ height: "100%" }}>
          <div>Loading...</div>
        </div>
      )}
    </PageContent>
  );
};

export default PullDetails;
