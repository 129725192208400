import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  TextareaAutosize,
  TextField,
  useMediaQuery,
  Box,
  Typography,
  Divider,
  FilledInput,
  InputAdornment,
  Grid,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useNavigate, useParams } from "react-router-dom";
import InventoryTracking from "../../../api/InventoryTracking";
import { AuthContext } from "../../../context/AuthContext";
import { TopNavContext } from "../../../context/TopNavContext";
import PageContent from "../../../component/PageContent";
import { useTheme } from "@emotion/react";
import { LoadingButton } from "@mui/lab";
import { DarkLightModeContext } from "../../../context/DarkLightModeContext";
import ReturnItemDetailsCard from "./ReturnItemDetailsCard";
import { Add, AddAPhoto, FileUpload, Search } from "@mui/icons-material";
import { v4 as uuidv4 } from "uuid";
import { SnackAlertContext } from "../../../context/SnackAlertContext";
import ListItem from "../../../component/ListItem";
import imageCompression from "browser-image-compression";
import * as Sentry from "@sentry/react";

const CreateNewReturn = () => {
  const [details, setDetails] = useState({
    imageBlobs: [],
    imageUrls: [],
    items: [{ item_text: "", quantity: 0 }],
    job_id: null,
    notes: "",
  });
  const [locations, setLocations] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [search, setSearch] = useState("");
  const uploadRef = useRef(null);
  const cameraRef = useRef(null);

  const { openSnackMessage } = useContext(SnackAlertContext);

  const { isDarkMode } = useContext(DarkLightModeContext);
  const { authToken, isMaterialAppAdmin } = useContext(AuthContext);
  const { setPageName } = useContext(TopNavContext);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  let returnIsPostable = details && !details.posted_on && !details.rejected_on;

  const handleFileSelect = async (event) => {
    const originalFile = event.target.files[0];
    console.log("originalFile instanceof Blob", originalFile instanceof Blob); // true
    console.log(`originalFile size ${originalFile.size / 1024 / 1024} MB`);

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    // Compress File (returns Blob instead of File)
    let compressedBlob = await imageCompression(originalFile, options);
    // Convert Compressed Blob back to File
    let compressedFile = new File([compressedBlob], originalFile.name, {
      type: originalFile.type,
    });
    const compressedFileUrl = URL.createObjectURL(compressedFile);
    console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`);
    console.log("compressedFile", compressedFile);
    console.log("compressedFileURL", compressedFileUrl);

    setDetails((prev) => ({
      ...prev,
      imageBlobs: [...prev.imageBlobs, compressedFile],
      imageUrls: [...prev.imageUrls, compressedFileUrl],
    }));
  };

  //   const update = async () => {
  //     try {
  //       setLoading(true);
  //       const response = await InventoryTracking.put(`/returns/${id}`, details, {
  //         headers: {
  //           Authorization: `Bearer ${authToken}`,
  //         },
  //       });
  //       console.log("UPDATED", response.data.return);
  //       setDetails(response.data.return);
  //       openSnackMessage("success", "Updated");
  //     } catch (error) {
  //       console.log(error);
  //       if (error.response) {
  //         // The request was made and the server responded with a status code
  //         // that falls out of the range of 2xx
  //         console.log(error.response.data);
  //         console.log(error.response.status);
  //         console.log(error.response.headers);
  //         openSnackMessage("error", error.response.data.error);
  //       } else {
  //         // Something went really bad
  //         console.log(error);
  //         openSnackMessage("error", error);
  //       }
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   const updateAndReject = async () => {
  //     try {
  //       setLoading(true);
  //       const response = await InventoryTracking.put(
  //         `/returns/${id}/reject`,
  //         details,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${authToken}`,
  //           },
  //         }
  //       );
  //       console.log("Posted", response.data.return);
  //       setDetails(response.data.return);
  //       openSnackMessage("success", "Rejected");
  //     } catch (error) {
  //       console.log(error);
  //       if (error.response) {
  //         // The request was made and the server responded with a status code
  //         // that falls out of the range of 2xx
  //         console.log(error.response.data);
  //         console.log(error.response.status);
  //         console.log(error.response.headers);
  //         openSnackMessage("error", error.response.data.error);
  //       } else {
  //         // Something went really bad
  //         console.log(error);
  //         openSnackMessage("error", error);
  //       }
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  const createAndPost = async () => {
    try {
      setLoading(true);
      const response = await InventoryTracking.post(
        `/returns/create-and-post`,
        details,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      openSnackMessage("success", "Posted");
      navigate(`/return/${response.data.return.id}`);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setLoading(false);
    }
  };

  // const updateImage = async (imageBlob) => {
  //   try {
  //     let formData = new FormData();
  //     formData.append("id", id);
  //     if (imageBlob) formData.append("image", imageBlob);
  //     else formData.append("image", null);

  //     console.log(formData);
  //     const response = await InventoryTracking.post(`/returns`, formData, {
  //       headers: {
  //         Authorization: `Bearer ${authToken}`,
  //         "content-type": "multipart/form-data",
  //       },
  //     });
  //     console.log("Image Update response", response.data);
  //     setDetails(response.data.material);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const updateNotes = async () => {
  //   try {
  //     let data = {
  //       id,
  //       notes,
  //     };
  //     const response = await InventoryTracking.post(`/returns`, data, {
  //       headers: {
  //         Authorization: `Bearer ${authToken}`,
  //       },
  //     });
  //     setDetails(response.data.material);
  //     setNotes(response.data.material.notes);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const getJobs = async () => {
    try {
      const response = await InventoryTracking.get(`/jobs?search=${search}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log(response.data);
      setJobs(response.data.jobs);
    } catch (error) {
      console.log(error);
    }
  };

  const getLocations = async () => {
    try {
      const response = await InventoryTracking.get(`/locations`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log(response.data);
      setLocations(response.data.locations);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getJobs();
  }, [search]);

  useEffect(() => {
    if (locations.length === 0) getLocations();
  }, []);

  return (
    <PageContent>
      <h2 style={{ textAlign: "center" }}>Create New Return</h2>
      {details ? (
        <div>
          <Box>
            <div>
              <Typography
                id="modal-modal-title"
                variant="p"
                sx={{ width: "100%", textAlign: "start", fontWeight: "bold" }}
              >
                Select Job
              </Typography>
              <FilledInput
                style={{ marginTop: 16, width: "100%" }}
                type="text"
                hiddenLabel={true}
                value={search}
                placeholder="Search Job Name"
                onChange={(e) => {
                  console.log("setSearch()");
                  setSearch(e.target.value);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                }
              />
            </div>
            <div
              style={{
                // display: "block",
                // maxHeight: 100,
                // maxWidth: "100%",
                // background: "#d2d2d2",
                // overflowX: "hidden",
                display: "block",
                maxHeight: 200,
                maxWidth: "100%",
                overflow: "scroll",
                overflowX: "hidden",
              }}
            >
              {jobs.map((job, index) => (
                <ListItem
                  title={`${job.projectid} - ${job.job_name}`}
                  details={[job.address]}
                  onClick={() => {
                    setDetails((prev) => ({ ...prev, job_id: job.id }));
                  }}
                  sx={{
                    background:
                      job.id === details.job_id ? "#5A5A5A" : "inherit",
                  }}
                />
              ))}
            </div>
          </Box>

          <h4>Images</h4>
          <Typography
            id="modal-modal-title"
            variant="p"
            component="p"
            sx={{ margin: 0 }}
          >
            Maximum 30 images
          </Typography>
          <Grid
            container
            padding={0}
            spacing={0}
            alignItems="center"
            justifyContent="center"
          >
            {details.imageUrls.length > 0 &&
              details.imageUrls.map((url) => (
                <img
                  src={url}
                  style={{
                    minHeight: "100px",
                    maxHeight: "100px",
                    minWidth: "100px",
                    maxWidth: "100px",
                    marginRight: "3px",
                    border: "1px solid black",
                  }}
                />
              ))}
          </Grid>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              marginTop: "4px",
            }}
          >
            <label>
              <input
                ref={uploadRef}
                style={{ display: "none" }}
                type="file"
                accept="image/*"
                name="images"
                // capture="environment"
                onChange={(event) => {
                  handleFileSelect(event);
                }}
              />
              <Button
                size="small"
                variant="contained"
                onClick={() => {
                  uploadRef.current.click();
                }}
              >
                <FileUpload />
                Upload
              </Button>
            </label>
            <label>
              <input
                ref={cameraRef}
                style={{ display: "none" }}
                type="file"
                accept="image/*"
                name="images"
                capture="environment"
                onChange={(event) => {
                  handleFileSelect(event);
                }}
              />
              <Button
                size="small"
                variant="contained"
                sx={{ marginLeft: "8px" }}
                onClick={() => {
                  cameraRef.current.click();
                }}
              >
                <AddAPhoto sx={{ marginRight: "4px" }} /> Capture
              </Button>
            </label>
          </div>

          <div style={{}}>Notes:</div>
          <TextareaAutosize
            disabled={!returnIsPostable}
            minRows={3}
            aria-label="empty textarea"
            placeholder="Empty..."
            style={{
              width: "100%",
              background: isDarkMode
                ? "#232323"
                : theme.palette.primary.extraDark,
              color: theme.palette.secondary.contrastText,
              borderColor: theme.palette.primary.main,
            }}
            value={details.notes}
            onChange={(e) => {
              setDetails((prev) => ({ ...prev, notes: e.target.value }));
            }}
          />

          <Box
            sx={{
              flexGrow: 1,
              marginTop: "10px",
            }}
          >
            <Grid2
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
              sx={{ marginLeft: "auto" }}
            >
              {details?.img_urls?.map((img, i) => (
                <Grid2
                  xs={2}
                  sm={4}
                  md={4}
                  key={i}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={img.image_url}
                    alt=""
                    style={{
                      maxHeight: "150px",
                      maxWidth: "150px",
                    }}
                  />
                </Grid2>
              ))}
            </Grid2>
          </Box>
          <Box sx={{ marginTop: "16px" }}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              sx={{
                width: "100%",
                textAlign: "center",
                fontWeight: "bold",
                marginTop: "32px",
              }}
            >
              Items
            </Typography>
            <div style={{ width: "100%" }}>
              {details.items &&
                details.items.map((item, index) => {
                  if (isMaterialAppAdmin) {
                    return (
                      <ReturnItemDetailsCard
                        isEditable={returnIsPostable}
                        key={`${index}${item.id}${item.randomKey}`}
                        jobId={details.job_id}
                        description={item.item_text}
                        setDescription={(description) => {
                          let itemsCopy = [...details.items];
                          itemsCopy[index].item_text = description;
                          setDetails((prev) => ({
                            ...prev,
                            items: itemsCopy,
                          }));
                        }}
                        quantity={item.quantity}
                        setQuantity={(quantity) => {
                          let itemsCopy = [...details.items];
                          itemsCopy[index].quantity = quantity;
                          setDetails((prev) => ({
                            ...prev,
                            items: itemsCopy,
                          }));
                        }}
                        returnFrom={item.return_from}
                        setReturnFrom={(from) => {
                          let itemsCopy = [...details.items];
                          itemsCopy[index].return_from = from;
                          itemsCopy[index].return_from_id = null; // Reset Return From ID
                          setDetails((prev) => ({
                            ...prev,
                            items: itemsCopy,
                          }));
                        }}
                        returnFromId={item.return_from_id}
                        setReturnFromId={(fromId) => {
                          let itemsCopy = [...details.items];
                          itemsCopy[index].return_from_id = fromId;
                          setDetails((prev) => ({
                            ...prev,
                            items: itemsCopy,
                          }));
                        }}
                        returnTo={item.return_to}
                        setReturnTo={(destination) => {
                          let itemsCopy = [...details.items];
                          itemsCopy[index].return_to = destination;
                          itemsCopy[index].item_id = null; // Reset inventory item_id
                          itemsCopy[index].provider_id = null; // Reset provider_id
                          itemsCopy[index].uom = null; // Reset uom id
                          itemsCopy[index].price = null; // Reset uom id
                          setDetails((prev) => ({
                            ...prev,

                            items: itemsCopy,
                          }));
                        }}
                        inventoryItemId={item.item_id}
                        setInventoryItemId={(itemId) => {
                          let itemsCopy = [...details.items];
                          itemsCopy[index].item_id = itemId;
                          setDetails((prev) => ({
                            ...prev,
                            items: itemsCopy,
                          }));
                        }}
                        vendorId={item.provider_id}
                        setVendorId={(vendorId) => {
                          let itemsCopy = [...details.items];
                          itemsCopy[index].provider_id = vendorId;
                          setDetails((prev) => ({
                            ...prev,
                            items: itemsCopy,
                          }));
                        }}
                        uom={item.uom}
                        setUom={(uomId) => {
                          let itemsCopy = [...details.items];
                          itemsCopy[index].uom = uomId;
                          setDetails((prev) => ({
                            ...prev,
                            items: itemsCopy,
                          }));
                        }}
                        price={item.price}
                        setPrice={(price) => {
                          let itemsCopy = [...details.items];
                          itemsCopy[index].price = price;
                          setDetails((prev) => ({
                            ...prev,
                            items: itemsCopy,
                          }));
                        }}
                        onRemove={() => {
                          let itemsCopy = [...details.items];
                          itemsCopy.splice(index, 1);
                          setDetails((prev) => ({
                            ...prev,
                            items: itemsCopy,
                          }));
                        }}
                      />
                    );
                  } else {
                    return (
                      <div
                        key={index}
                        style={{
                          marginTop: 10,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        <TextField
                          sx={{ flex: 1 }}
                          name="Description"
                          label="Description"
                          type="text"
                          value={item.item_text}
                        />
                        <TextField
                          sx={{ width: "80px", marginLeft: "8px" }}
                          name="Quantity"
                          label="Quantity"
                          value={item.quantity}
                        />
                      </div>
                    );
                  }
                })}
            </div>
            {returnIsPostable && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Button
                  sx={{ marginTop: "15px", width: 150 }}
                  variant="contained"
                  startIcon={<Add />}
                  onClick={() => {
                    let itemsCopy = [...details.items];
                    itemsCopy.push({
                      randomKey: uuidv4(), // To prevent ui bug of lingering inputs when removing an item (data on index x stays on index x)
                    });
                    setDetails((prev) => ({
                      ...prev,
                      items: itemsCopy,
                    }));
                  }}
                >
                  Add Item
                </Button>
              </div>
            )}
          </Box>
          {returnIsPostable && (
            <Box
              sx={{
                paddingTop: "16px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <LoadingButton
                loading={loading}
                variant="outlined"
                onClick={createAndPost}
              >
                Post
              </LoadingButton>
            </Box>
          )}
        </div>
      ) : (
        <div style={{ height: "100%" }}>
          <div>Loading...</div>
        </div>
      )}
    </PageContent>
  );
};

export default CreateNewReturn;
